<template>
    <main>
        <div class="row  m-0">
            <div class="col-xl-12 mt-4">
                <h1>Historiques</h1>
            </div>
        </div>
        <div class="row mt-5 ">
            <div class="col-xl-8 m-auto">
                <table class="text-center">
                    <thead>
                    <tr>
                        <th scope="col">Numero de Facture</th>
                        <th scope="col">Date de Transaction</th>
                        <th scope="col">Quantite</th>
                        <th scope="col">Cree par</th>


                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in historiques" :key='item.id'>
                        <td data-label="Numero de Facture"> {{item.facture.num_facture}}</td>
                        <td data-label="Date de Transaction"> {{item.facture.date_transaction}}</td>
                        <td data-label="Quantite"> {{item.qte}}</td>
                        <td data-label="Cree par"> {{item.facture.user.name}}</td>


                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </main>
</template>

<script>

    export default {
        name: "Historiques",
        data() {
            return {
                historiques: [],


            }
        },
        created() {
            this.getHistoriques();

        },
        methods: {


            getHistoriques() {
                this.$http
                    .get('/stocks/history/'+ this.$route.params.id)
                    .then(response => {
                        this.historiques = response.data;
                        console.log(response)

                    })
                    .catch(error => {
                        console.log(error)
                        this.$alertify.error("Il y a une erreur, réessayez !");
                    })

            },



        },

    }
</script>

<style scoped src="@/assets/table.css">

</style>